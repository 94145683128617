import type { AxiosResponse } from 'axios';
import { mapState, mapGetters } from 'vuex';
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration';
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import ArticleCard from '~/components/ArticleCard/ArticleCard.vue';
import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
import ArticleCardSmallPlaceholder from '@/placeholders/ArticleCardSmallPlaceholder/ArticleCardSmallPlaceholder.vue';
import ArticleCardSmall from '@/components/lh-ui/ArticleCard/ArticleCardSmall/ArticleCardSmall.vue';

import {
  error,
  getEditLinkHref,
  getFullUrl,
  isArray,
  isElementInViewport,
} from '~/utils';
import { getMixedItemsArray } from '~/utils/arrays';
import ArticleCardContainerMixin from '~/mixins/ArticleCardContainerMixin';
import FavoriteMethodsMixin from '~/mixins/FavoriteMethodsMixin';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import { UPDATE_CURRENT_ARTICLES } from '~/constants';
import { isHomePage, isArchivePage, isTopPage, isHealthPath, isHomeHealth } from '~/router';
import { getArticleFetchApi } from '~/utils/apiUtils';
import ThePaginator from '@/components/lh-ui/ThePaginator/ThePaginator.vue';
import ThePaginatorWrapper from '~/containers/ThePaginatorWrapper.vue';

const MAX_PAGES = 4;

export default ArticleCardContainerMixin.extend({
  name: 'ArticleCardContainer',

  mixins: [FavoriteMethodsMixin, UpdatingViewsCountMixin],

  props: {
    showPagination: {
      type: Boolean,
      default: true,
    },
    pageTitle: {
      type: String,
      default: '',
    },
    //! ВАЖНО: тут не индекс, поэтому потом нужно сделать -1
    startFrom: {
      type: Number,
      default: 1,
    },
    allImagesLazy: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ArticleCardSmallPlaceholder,
    ArticleCard,
    ThePaginator,
    WidgetsRendererContainer,
    ArticleCardSmall,
    IntersectionObserver,
    LazyHydrate,
    ThePaginatorWrapper,
  },

  data () {
    return {
      page: 1 as number,
      loading: false as boolean,

      // Для аналитики. Отслеживание скролла списка материалов. Отправляется через каждые 20 постов.
      // В Label порядковый номер поста: 20, 40, 60, 80, 100, 120
      // Первые 5 карточек в TheTvContainer
      trackedCardNumbers: [15, 35, 55, 75, 95, 115] as number[],

      articlesWithLoadedPixels: [] as number[],
    };
  },

  computed: {
    ...mapState(['folders', 'user']),
    ...mapGetters(['isHealthCategory']),
    articles (): Array<IArticle> {
      const articles = this.$store.getters.currentArticles;

      if (this.isHealthRoute) { return articles; }

      const page = this.$utils.getActivePage(this.$route);

      // первые 5 статей на главной отображаются в телевизоре
      const startFrom = this.isHomePage ? 5
        : page > 1 ? 0
          : Math.max(0, this.startFrom - 1); // если с бека приходит 0
      return articles.slice(startFrom);
    },
    totalPages (): number { return this.$store.getters.currentTotalPages; },
    favorites (): Array<IFavoriteItem> { return this.$store.state.articles.userRelated.favorites; },
    activePage (): number { return this.$utils.getActivePage(this.$route); },

    routeBeginning (): string {
      const { pathMatch, year, month, day } = this.$route.params;

      const prefix = this.isHealthRoute ? '/health' : '';

      switch (this.$route.name) {
        case 'list-all':
          return `${prefix}/list/`;
        case 'topics-all':
          return `${prefix}/topics/${pathMatch}`;
        case 'tag-all':
          return `${prefix}/tag/${pathMatch}`;
        case 'author-all':
          return `${prefix}/author/${pathMatch}`;
        case 'stream-all':
          return `${prefix}/stream/${pathMatch}`;
        case 'top-all':
          return `${prefix}/top/${pathMatch?.length ? pathMatch : 'week'}`;
        case 'archive-year':
          return `${prefix}/${year}`;
        case 'archive-month':
          return `${prefix}/${year}/${month}`;
        case 'archive-day':
          return `${prefix}/${year}/${month}/${day}`;
        case 'health-article':
          return this.$route.path;
      }

      return `${prefix}/list/`;
    },
    isPaginatorVisible (): boolean {
      return this.showPagination && !(this.isHomePage || this.isHealthHome);
    },
    widgetsBetweenPosts (): Array<TWidget> {
      return this.$store.getters.currentBetweenPostsWidgets;
    },
    articlesAndWidgets (): Array<any> {
      // смешанные статьи и виджеты
      return getMixedItemsArray(this.articles, this.widgetsBetweenPosts, 5, 1, false);
    },
    isHealthHome (): boolean {
      const { name } = this.$route;
      return isHomeHealth(name);
    },
    isHomePage (): boolean {
      return this.$route.name === 'index';
    },
    isTopPage (): boolean {
      return isTopPage(this.$route.name);
    },
    isArchivePage (): boolean {
      return isArchivePage(this.$route.name);
    },
    isHealthRoute (): boolean {
      return isHealthPath(this.$route.path);
    },
    isIntersectEnabled (): boolean {
      return (this.isHomePage || this.isHealthCategory) && this.totalPages > 1;
    },
    loadingNeeded (): boolean {
      return this.$store.getters.currentArticles.length < 115;
    },
    placeholderVisible (): boolean {
      return (this.isHomePage && this.loadingNeeded);
    },
    getArchiveName (): string {
      const { name } = this.$route;

      switch (true) {
        case isHomePage(name):
          return 'главная';
        case isArchivePage(name) && !isTopPage(name):
          return 'архивы';
        case isTopPage(name):
          return 'лучшее';
        default:
          return '';
      }
    },
  },

  watch: {
    '$route.fullPath' () {
      this.articlesWithLoadedPixels = [];
    },

    activePage (): void {
      window.scrollTo(0, 0);
    },
  },

  methods: {
    getEditLinkHref,
    isArray,
    sendEvent (slug: string) {
      this.$sendYandexMetrika({
        level1: 'Комментарии_переход к комментариям',
        level4: getFullUrl(slug),
        level6: this.getArchiveName,
      });
    },
    async addArticles (): Promise<void> {
      if (this.isHealthCategory) {
        // для страниц подкатегорий может быть лонгрид со всеми статьями подкатегорий
        this.addArticlesHealthCategory();
        return;
      }

      if (this.page < MAX_PAGES && !this.loading) {
        this.loading = true;
        this.page = this.page + 1;

        const articleApi = getArticleFetchApi(this.$route, this);

        const { status, data }: AxiosResponse = await articleApi.fetchArticles(
          this.$route,
          this.page,
        );
        this.loading = false;

        if (status === 200) {
          const { fullPath, hash } = this.$route;
          const path = fullPath.replace(hash, '');
          const { items } = data;
          const { commit } = this.$store;
          commit(UPDATE_CURRENT_ARTICLES, { path, newArticles: items });

          // Иногда если пользователь доскроллил страницу до конца,
          // то новые статьи добавляются сверху и не происходит скролл страницы вверх.
          // Поэтому если плейсхолдер сейчас виден на экране или находится выше окна, загружаем следующие статьи.
          const observerEl = (this.$refs.observer as Vue).$el as HTMLElement;
          if (isElementInViewport(observerEl) || observerEl.getBoundingClientRect().top <= 0) {
            this.addArticles();
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Ошибка при автоподгрузке статей');
          error('Ошибка при автоподгрузке статей');
        }
      }
    },
    async addArticlesHealthCategory () {
      // бэк отправляет 0, в том случае если требуется лонгрид, в ином случае ленивая загрузка не требуется
      if (this.startFrom !== 0) { return; }

      try {
        const currentPageData = this.$store.getters.currentPageData;

        if (this.page < currentPageData.totalPages && !this.loading) {
          const { fullPath, hash } = this.$route;
          const { slug } = this.$route.params;
          const path = fullPath.replace(hash, '');

          this.loading = true;
          this.page = this.page + 1;

          const archiveCategoryData = await this.$healthApi.fetchCategory(
            slug,
            'posts',
            { page: this.page, limit: 30 },
          );

          this.loading = false;

          this.$store.commit(UPDATE_CURRENT_ARTICLES, { path, newArticles: archiveCategoryData.items });
          // Иногда если пользователь доскроллил страницу до конца,
          // то новые статьи добавляются сверху и не происходит скролл страницы вверх.
          // Поэтому если плейсхолдер сейчас виден на экране или находится выше окна, загружаем следующие статьи.
          const observerEl = (this.$refs.observer as Vue).$el as HTMLElement;
          if (isElementInViewport(observerEl) || observerEl.getBoundingClientRect().top <= 0) {
            this.addArticlesHealthCategory();
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка при автоподгрузке статей');
        error('Ошибка при автоподгрузке статей');
      }
    },

    getIntersectionObserverOptions (): IntersectionObserverInit {
      if (process.client) {
        return {
          rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`,
        };
      } else {
        return {
          rootMargin: '0px 0px 0px 0px',
        };
      }
    },

    onClickArticleCardSmall (article: IArticle, order: number): void {
      this.onAbTitleEvent(article, 'click');

      this.$sendYandexMetrika({
        level1: 'Выбор материала_Списочная карточка',
        level4: getFullUrl(article.url),
        level5: order,
        level6: this.pageTitle || 'unknown',
        level8: article.teaserUrl ? 'Тизер' : 'Списочная карточка',
      });
    },

    getLabel (path: string) {
      switch (true) {
        case path.includes('month'):
          return 'За месяц';
        case path.includes('year'):
          return 'За год';
        case path.includes('week'):
        default:
          return 'За неделю';
      }
    },

    onClickAuthor (): void {},

    onClickCategory (link: string, titleCategory: string, _title: string): void {
      this.$sendYandexMetrika({
        level1: 'Выбор рубрики',
        level4: getFullUrl(link),
        level5: titleCategory,
        level6: this.pageTitle || 'unknown',
      });
    },

    getTrackingArticleNumber (id: IArticle['id']): number {
      const index = this.articles.findIndex(item => item.id === id);
      return index + 1;
    },

    isTrackingForAnalyticsNeeded (article: IArticle): boolean {
      const number = this.getTrackingArticleNumber(article.id);
      return this.trackedCardNumbers.includes(number) && isHomePage(this.$route.name);
    },

    calcComponentName (article: IArticle): 'div' | 'IntersectionObserver' {
      const isTrackingForAnalyticsNeeded = this.isTrackingForAnalyticsNeeded(article);
      const hasAbTitlesData = this.hasAbTitlesData(article?.abTitlesData);
      const hasTestPixel = Boolean(article?.testPixel?.length);

      const isTrackingNeeded = hasAbTitlesData || hasTestPixel || isTrackingForAnalyticsNeeded;
      return isTrackingNeeded ? 'IntersectionObserver' : 'div';
    },

    onAppear (article: IArticle): void {
      const isTrackingForAnalyticsNeeded = this.isTrackingForAnalyticsNeeded(article);
      if (isTrackingForAnalyticsNeeded) {
        const trackingArticleNumber = this.getTrackingArticleNumber(article.id);
        this.trackedCardNumbers = this.trackedCardNumbers.filter(item => item !== trackingArticleNumber);
      }

      this.onAbTitleEvent(article, 'view');

      const testPixel = article?.testPixel;
      const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
      if (testPixel?.length && isNotLoadedPixel) {
        testPixel.forEach(url => this.$axios(url, { withCredentials: true }));
        this.articlesWithLoadedPixels.push(article.id);
      }
    },

    getArticleCardClass (itemIndex: number, articleIndex: number): string {
      const isFiveElement = (articleIndex === 4);
      const nextItemSecond = this.articlesAndWidgets[itemIndex + 1]?.second ?? [];
      // если у следующего элемента articlesAndWidgets есть виджеты
      const nextWidgetExists = nextItemSecond.length > 0;
      return (isFiveElement && nextWidgetExists && this.isHomePage) ? 'article-card__small-wrapper_above-widget' : '';
    },

    getTypePage (): string {
      switch (true) {
        case this.isTopPage:
          return 'Лучшее';
        case this.isHomePage:
          return 'Главная';
        case this.isArchivePage:
          return 'Архивы';
        case this.isHealthHome:
          return 'Главная здоровье';
        default:
          return '';
      }
    },

    getImageLoadingType (itemIndex: number, articleIndex: number): string {
      if (this.allImagesLazy) { return 'lazy'; }
      if (itemIndex === 0 && articleIndex < 3) { return 'eager'; }
      if (itemIndex > 1) { return 'lazy'; }
      return 'auto';
    },
  },
});
